import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../lib/i18n';
import { COLORS } from '../../lib/colors';
import {
  has1Lowercase,
  has1Number,
  has1SpecialCharacter,
  has1Uppercase,
  hasAtleastNCharacters,
} from '../../lib/validators';
import { Caption } from '../typography/legacy/Text';

const StyledMeterStrengthCaption = styled(Caption)({
  width: '100%',
  textAlign: 'left',
  marginLeft: '14px',
  fontSize: '1.2rem',
});

const StyledMeterRequirementsCaption = styled(Caption)({
  width: '100%',
  textAlign: 'left',
  color: '#d32f2f',
  marginLeft: '14px',
  fontSize: '1.2rem',
});

const StyledLinearProgress = styled(LinearProgress)({
  width: '100%',
});

export interface PasswordRule {
  label: string;
  validator: (pw: string) => boolean;
  required: boolean;
}
export type PasswordRules = PasswordRule[];

enum PasswordStrengthLevel {
  Weak,
  Good,
  Great,
}

const getPasswordStrength = (
  passwordRules: PasswordRules,
  password: string,
): { strength: number; requirements: string[]; suggestions: string[] } =>
  passwordRules.reduce(
    (
      acc: { strength: number; requirements: string[]; suggestions: string[] },
      { label, required, validator }: PasswordRule,
    ) => {
      if (validator(password)) {
        return { ...acc, strength: acc.strength + 1 };
      }
      if (required) {
        return { ...acc, strength: 1, requirements: [...acc.requirements, label] };
      }
      return {
        ...acc,
        suggestions: [...acc.suggestions, label].filter(
          (suggestion) => suggestion !== '1 special character',
        ),
      };
    },
    { strength: 0, requirements: [], suggestions: [] },
  );

const getStrengthLevel = (strength: number, numRules: number): PasswordStrengthLevel => {
  if (strength <= 1) {
    return PasswordStrengthLevel.Weak;
  }
  if (strength / numRules <= 0.8) {
    return PasswordStrengthLevel.Good;
  }
  return PasswordStrengthLevel.Great;
};

interface Props {
  customPasswordRules?: PasswordRules;
  password?: string;
}

export const PasswordMeter: React.FC<Props> = ({ password, customPasswordRules }) => {
  const { t } = useTranslation('signUp');

  const passwordRules = customPasswordRules ?? [
    {
      label: t('1Number', '1 number'),
      validator: has1Number,
      required: true,
    },
    {
      label: t('1LowercaseLetter', '1 lowercase letter'),
      validator: has1Lowercase,
      required: true,
    },
    {
      label: t('1UppercaseLetter', '1 uppercase letter'),
      validator: has1Uppercase,
      required: true,
    },
    {
      label: t('8OrMoreCharacters', '8 or more characters'),
      validator: (val) => hasAtleastNCharacters(val, 8),
      required: true,
    },
    {
      label: t('1specialCharacter', '1 special character'),
      validator: has1SpecialCharacter,
      required: false,
    },
    {
      label: t('12OrMoreCharacters', '12 or more characters'),
      validator: (pw: string) => pw.length >= 12,
      required: false,
    },
  ];
  const strengthNames = {
    [PasswordStrengthLevel.Weak]: t('weak', 'Weak'),
    [PasswordStrengthLevel.Good]: t('good', 'Good'),
    [PasswordStrengthLevel.Great]: t('great', 'Great'),
  };
  const strengthClasses = {
    [PasswordStrengthLevel.Weak]: COLORS.O4MeRed,
    [PasswordStrengthLevel.Good]: COLORS.O4MeOrange,
    [PasswordStrengthLevel.Great]: COLORS.O4MeDarkBlue,
  };

  if (password && password.length > 0) {
    const { strength, requirements, suggestions } = getPasswordStrength(passwordRules, password);
    const strengthLevel = getStrengthLevel(strength, passwordRules.length);

    return (
      <>
        <StyledLinearProgress
          variant="determinate"
          value={(strength / passwordRules.length) * 100}
          sx={{ color: strengthClasses[strengthLevel] }}
        />
        <StyledMeterStrengthCaption>
          {t('passwordStrength', {
            defaultValue: 'Password Strength: {{strength}}',
            strength: strengthNames[strengthLevel],
          })}
        </StyledMeterStrengthCaption>
        {requirements.length > 0 && (
          <StyledMeterRequirementsCaption>
            {t('passwordRequirements', {
              defaultValue: 'Password needs: {{requirements}}',
              requirements: requirements.join(', '),
            })}
          </StyledMeterRequirementsCaption>
        )}
        {requirements.length === 0 && suggestions.length > 0 && (
          <StyledMeterStrengthCaption>
            {t('passwordSuggestions', {
              defaultValue: 'Password suggestions: {{suggestions}}',
              suggestions: suggestions.join(', '),
            })}
          </StyledMeterStrengthCaption>
        )}
      </>
    );
  }

  return null;
};
