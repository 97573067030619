import { ButtonIconPlacement } from './button.types';

type IconPlacementPropMap = {
  [K in ButtonIconPlacement]: string;
};

export const materialIconPropMap: IconPlacementPropMap = {
  left: 'startIcon',
  right: 'endIcon',
};
