import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { COLORS } from '../../../lib/colors';

export const AuthStyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: '4.8rem',
  rowGap: '1.6rem',
  border: `1px solid ${COLORS.black_12}`,
  [theme.breakpoints.down('md')]: {
    padding: '2.4rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '1.6rem',
  },
}));

export const AuthStyledDiv = styled('div')(({ theme }) => ({
  display: 'grid',
  gridAutoColumns: 'minmax(0, 1fr)',
  columnGap: theme.spacing(2.5),
  gridAutoFlow: 'column',
}));

export const AuthStyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: theme.palette.divider,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.divider,
    },
  },
}));
