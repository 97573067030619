import Typography from '@mui/material/Typography';
import React from 'react';

import { EmphasisColorMap } from './typography.const';
import { TypographyProps } from './typography.types';

export const Body2: React.FC<TypographyProps> = ({ className, emphasis = 'high', ...props }) => {
  return (
    <Typography
      variant="body2"
      className={className}
      color={EmphasisColorMap[emphasis]}
      {...props}
    />
  );
};
