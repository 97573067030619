import Smartphone from '@mui/icons-material/Smartphone';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Caption, Tab } from './typography/legacy/Text';
import { logEvent } from '../lib/analytics';

const StyledA = styled('a')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.mode === 'dark' ? 'white' : theme.palette.text.secondary,
  textDecoration: 'none',
}));

const IconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

export const MobileAppOffer: React.FC = () => {
  const { t } = useTranslation(['mobileAppOffer']);
  const router = useRouter();

  const emailSubject = t(
    'shareEmailSubject',
    'Your doctor has been using Outcomes4Me to find you a clinical trial',
  );
  const emailBody = t(
    'shareEmailBody',
    'Learn more by downloading the Outcomes4Me app\n\nTake an active role in your healthcare\n\nYour doctor has been browsing clinical trials for you using the Outcomes4Me platform and recommended we reach out to let you know. To take an active role in your healthcare download the Outcomes4Me app to help your doctor find relevant clinical trials for you, understand more about your treatment options, and review your medical records.\n\nDownload Now: https://www.outcomes4me.com/download',
  );
  const mailTo = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
    emailBody,
  )}`;

  return (
    <Link href={mailTo} passHref legacyBehavior>
      <StyledA
        role="link"
        tabIndex={0}
        onClick={() => {
          logEvent('mobile_app_offer_send', {
            category: 'Share',
          });
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            logEvent('mobile_app_offer_send', { category: 'Share' });
            void router.push(mailTo);
          }
        }}
      >
        <IconWrapper>
          <Smartphone />
        </IconWrapper>
        <div>
          <Tab>{t('shareAppTitle', 'Share the Outcomes4Me patient app')}</Tab>
          <Caption>
            {t('shareAppSubtitle', 'Personalized treatment options, resources & more')}
          </Caption>
        </div>
      </StyledA>
    </Link>
  );
};
