import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import { ChallengeName, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';

import { postToApi } from './api';

// CognitoUser type is incorrect, so this is a workaround
// https://github.com/aws-amplify/amplify-js/issues/4927
export interface CognitoUserExt extends CognitoUser {
  challengeName?: ChallengeName;
  signInUserSession: {
    idToken: {
      jwtToken: string;
    };
    refreshToken: {
      token: string;
    };
    accessToken: {
      jwtToken: string;
    };
  };
  username: string;
}

export type ChallengeResponse = (cognitoUser: CognitoUserExt) => void;

export type CognitoUserType = 'admin' | 'provider' | 'patient' | 'piportal';

export const authViaApi = async (userType: CognitoUserType): Promise<void> => {
  // TODO do we need this current authenticated user call? Previously we were assigning the user to a variable but we aren't using it.
  await Auth.currentAuthenticatedUser();
  const session = await Auth.currentSession();
  await postToApi(
    `/auth/cognito/${userType}`,
    { idToken: session.getIdToken().getJwtToken() },
    { rawData: true },
  );
};

export const refreshAuth = async (): Promise<CognitoUserSession> => {
  return Auth.currentSession();
};

/*
  Default authenticationFlowType from https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */
export const configureAmplify = ({
  userPoolId,
  userPoolWebClientId,
  authenticationFlowType = 'USER_SRP_AUTH',
  redirectTrailingSlash = false,
  customRedirectSignIn,
}: {
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType?: string;
  redirectTrailingSlash?: boolean;
  customRedirectSignIn?: string;
}): void => {
  const defaultRedirect = `${process.env.NEXT_PUBLIC_SITE_URL}${redirectTrailingSlash ? '/' : ''}`;
  Amplify.configure({
    Auth: {
      userPoolId,
      userPoolWebClientId,
      authenticationFlowType,
    },
    oauth: {
      domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
      responseType: 'code',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: customRedirectSignIn ?? defaultRedirect,
      redirectSignOut: defaultRedirect,
    },
  });
};

export const signInToCognito = async (
  username: string,
  password: string,
): Promise<CognitoUserExt> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Auth.signIn(username.toLowerCase(), password);
};

export const mfaSignInToCognito = async (
  cognitoUser: CognitoUserExt,
  mfaCode: string,
): Promise<void> => {
  const { challengeName } = cognitoUser;
  switch (challengeName) {
    case 'SMS_MFA':
    case 'SOFTWARE_TOKEN_MFA':
      await Auth.confirmSignIn(cognitoUser, mfaCode, challengeName);
      break;
    case 'CUSTOM_CHALLENGE':
      await Auth.sendCustomChallengeAnswer(cognitoUser, mfaCode);
      break;
    default:
      throw new Error(
        `Challenge name should be SMS_MFA, SOFTWARE_TOKEN_MFA, or CUSTOM_CHALLENGE, not: ${
          challengeName || 'undefined'
        }`,
      );
  }
};

export const resetPasswordRequest = async (username: string): Promise<void> => {
  await Auth.forgotPassword(username);
};

export const resetPassword = async (
  username: string,
  resetCode: string,
  newPassword: string,
): Promise<void> => {
  await Auth.forgotPasswordSubmit(username, resetCode, newPassword);
};

export const setNewPasswordCognito = async (
  newPassword: string,
  cognitoUser: CognitoUserExt,
): Promise<CognitoUserExt> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const user: CognitoUserExt = await Auth.completeNewPassword(cognitoUser, newPassword);
  return user;
};

export const getMfaSetupCodeCognito = async (cognitoUser: CognitoUserExt): Promise<string> => {
  return Auth.setupTOTP(cognitoUser);
};

export const verifyMfaSetupCognito = async (
  cognitoUser: CognitoUserExt,
  mfaCode: string,
): Promise<void> => {
  await Auth.verifyTotpToken(cognitoUser, mfaCode);
  await Auth.setPreferredMFA(cognitoUser, 'TOTP');
};

export const setEmailMfa = async (cognitoUser: CognitoUserExt, value: boolean): Promise<void> => {
  await Auth.updateUserAttributes(cognitoUser, {
    'custom:email_mfa': value.toString(),
  });
};

export const getEmailMfa = async (cognitoUser: CognitoUserExt): Promise<boolean | null> => {
  const userAttributes = await Auth.userAttributes(cognitoUser);
  const emailMfa = userAttributes.find((attr) => attr.getName() === 'custom:email_mfa');
  const value = emailMfa?.getValue();
  return value === undefined ? null : value === 'true';
};

type UserRegistration = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  birthYear: number;
};

export const userRegisterCognito = async ({
  email,
  firstName,
  lastName,
  birthYear,
  password,
}: UserRegistration): Promise<CognitoUser> => {
  const birthdate = `01/01/${birthYear}`;
  const { user, userConfirmed } = await Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      given_name: firstName,
      family_name: lastName,
      birthdate,
    },
  });
  if (userConfirmed) {
    return user;
  }
  throw new Error('User not registered');
};

export function loginWithSocialProvider(provider: CognitoHostedUIIdentityProvider) {
  return Auth.federatedSignIn({ provider });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function currentAuthenticatedUser(): Promise<any> {
  return Auth.currentAuthenticatedUser();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function signOut(): Promise<any> {
  return Auth.signOut();
}
