import isEmail from 'validator/lib/isEmail';

export const has1Number = (str: string): boolean => /.*[0-9].*/.test(str);

export const has1Lowercase = (str: string): boolean => /.*[a-z].*/.test(str);

export const has1Uppercase = (str: string): boolean => /.*[A-Z].*/.test(str);

export const hasAtleastNCharacters = (str: string, n: number): boolean => str.length >= n;

export const has1SpecialCharacter = (str: string): boolean =>
  /[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(str);

export const isValidEmail = (str: string): boolean => isEmail(str);

export const isAtleast18YearsAgo = (year: number): boolean => year <= new Date().getFullYear() - 18;
