import { TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React, { FC, FunctionComponent, PropsWithChildren } from 'react';

import { MobileAppOffer } from '../MobileAppOffer';
import { Body2 } from '../typography/Body2';
import { H4 } from '../typography/H4';

type AuthContainerProps = PropsWithChildren<{
  aboveHeader?: React.ReactNode;
  title: string;
  subtitle?: string;
  TitleComponent?: FunctionComponent<TypographyProps>;
  SubtitleComponent?: FunctionComponent<TypographyProps>;
  showMobileAppOffer?: boolean;
  className?: string;
}>;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '3.2rem',
    paddingRight: '3.2rem',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '2.4rem',
    paddingRight: '2.4rem',
    rowGap: '2.4rem',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    rowGap: '1.6rem',
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '3.2rem',
  maxWidth: '78rem',
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    rowGap: '2.4rem',
  },
  [theme.breakpoints.down('sm')]: {
    rowGap: '1.6rem',
  },
}));

const HeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '0.8rem',
  textAlign: 'left',
});

const DownloadLink = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
}));

const AuthContainer: FC<AuthContainerProps> = ({
  aboveHeader,
  children,
  title,
  TitleComponent = H4,
  subtitle,
  SubtitleComponent = Body2,
  showMobileAppOffer,
}) => {
  return (
    <Wrapper>
      <ContentWrapper>
        {aboveHeader}
        <HeaderWrapper>
          <TitleComponent>{title}</TitleComponent>
          <SubtitleComponent emphasis="medium">{subtitle}</SubtitleComponent>
        </HeaderWrapper>
        {children}
        {showMobileAppOffer && (
          <DownloadLink>
            <MobileAppOffer />
          </DownloadLink>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default AuthContainer;
