import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

import { materialIconPropMap } from './button.const';
import { ButtonProps } from './button.types';

const Button = styled(MuiButton)(({ theme, fullWidth }) => ({
  borderRadius: '100px',
  fontSize: '1.9rem',
  height: '5.6rem',
  lineHeight: '100%',
  fontWeight: '500',
  boxShadow: 'none',
  padding: '9px 25px',
  width: fullWidth ? '100%' : 'auto',
  '&&:disabled': {
    color: theme.palette.primary.contrastText,
  },
  '&&:hover': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
  },
  '&&:active': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
  },
}));

export const PrimaryButton: FC<ButtonProps> = ({
  className,
  fullWidth,
  icon,
  iconPlacement = 'left',
  disabled,
  onClick,
  children,
  style,
  ...props
}) => {
  return (
    <Button
      variant="contained"
      className={className}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      style={style}
      {...{ [materialIconPropMap[iconPlacement]]: icon }}
      {...props}
    >
      {children}
    </Button>
  );
};
